body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

strong {
  font-weight: normal;
}

.serif {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.text-attention {
	color: #e6378d;
}

.header-title {
  h1 {
    text-align: center;
    @media screen and (max-width: 768px) {
      margin: 10px 0 10px 50px;
    }
    @media screen and (min-width: 768px) {
      margin: 10px 0 10px 20px;
    }
    @media screen and (min-width: 992px) {
      margin: 10px 0 10px 20px;
    }
  }
  p {
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #555;
  }
}

.navbar-brand {
  margin-right: 2em;
}

#global-nav {
  margin-bottom: 0;
  border-radius: 0;
}


.breadcrumb > li + li:before {
  content: "> ";
  padding: 0 5px;
  color: #ccc;
}

.display-none {
  display: none;
}

th {
  width: 20%;
}

/* スライド画像 */

.jumbotron {
  padding: 0;
  .container {
    text-align: center;
    color: #ffffff;
    -moz-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    -webkit-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    -ms-text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  }
  .bx-wrapper {
    border: none;
    box-shadow: none;
  }
  .slide {
    padding: 0;
    text-shadow: 1px 1px 11px rgba(0, 0, 0, 0.8);
    @media screen and (max-width: 768px) {
      height: 300px;
    }
    @media screen and (min-width: 768px) {
      height: 400px;
      padding-top: 0;
      padding-bottom: 0;
    }
    @media screen and (min-width: 992px) {
      height: 500px;
    }
    a.btn {
      text-shadow: none;
    }
  }
}

#slide_01 {
  background-image: url(img/flower_05.jpg);
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

#slide_02 {
  background-image: url(img/flower_10.jpg);
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

#slide_03 {
  background-image: url(img/flower_09.jpg);
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
}

#contents-introduction {
  margin-top: -120px;
  padding-top: 120px;
}

#contents-gift {
  background-image: url(img/flower_16.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  color: #fff;
  @media screen and (max-width: 575px) {
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

#contents-wedding {
  background-image: url(img/wedding_01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  @media screen and (max-width: 575px) {
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

// コンセプト背景
#contents-concept {
  background: linear-gradient(to bottom, rgba(22,47,57,1) 0%,rgba(25,50,61,1) 38%,rgba(75,88,86,1) 62%,rgba(43,66,72,1) 100%);
  color: #fff;
}

// ギャラリー背景
#contents-gallery {
  background-image: url(img/bg-g.jpg);
}

#gallery a:hover img.img-thumbnail {
  opacity: 0.7;
}

// スタッフ募集背景
#contents-recruit {
	
	background: rgb(40,22,11);
  background: linear-gradient(0deg, rgba(40,22,11,1) 0%, rgba(115,96,65,1) 100%);
  background-image: url(img/recruit-04.jpg);
  background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-position: center 10%;
  @media screen and (max-width: 575px) {
    background-size: auto 100%;
    background-attachment: scroll;
  }
}


.d-inline-block {
  display: inline-block;
}

.display-table p {
  display: table-cell;
  vertical-align: middle;
}

/* ボタンのカスタマイズ */
.btn-dark {
  color: #fff;
  background-color: #e6378d;
  border-color: #dd368d;
  &:hover {
    color: #fff;
    background-color: #cf2b7c;
    border-color: #cf2b7c;
  }
}

/* カード内のリンク */
a.card-link:hover {
  text-decoration: none;
  img {
    opacity: 0.8;
    transition-duration: 0.3s;
  }
  .card-body {
    opacity: 0.6;
    transition-duration: 0.3s;
  }
}

.btn-pink {
  color: #fff;
  background-color: #e6378d;
  border-color: #dd368d;
  &:hover {
    color: #fff;
    background-color: #cf2b7c;
    border-color: #cf2b7c;
  }
}
.btn-green {
  color: #fff;
  background-color: #52b55d;
  border-color: #50b560;
  &:hover {
    color: #fff;
    background-color: #39994b;
    border-color: #39994b;
  }
}
.bg-white-alpha {
  background-color: rgba(255,255,255,0.5);
  z-index: 990;
}

/* Drawerハンバーガーメニュー */
.drawer-hamburger {
  padding-top: 0.75rem;
  padding-right: .5rem;
  padding-bottom: 1.5rem;
  padding-left: .5rem;
}
.drawer-nav {

  .list-group-item {
    border: 1px solid rgba(255,255,255,.125);
    padding: .75rem 1.25rem;
    background-color: #212529;
    color: #eee;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    &:last-child {
      border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    }
    .fas.fa-info-circle {
      color: #dd368d;
    }
  }
  .list-group-item-action {
    &:hover {
      background-color: #ddd;
      color: #333;
      transition-duration: .3s;
    }
  }
}
.inner-link {
  padding-top: 60px;
  margin-top: -60px;
}
/* フォーム埋め込み */
#modal-form iframe {
  border: 0 !important;
  overflow-y: scroll;
  -ms-overflow-style: none;    /* IE, Edge 対応 */
  scrollbar-width: none;       /* Firefox 対応 */
  &::-webkit-scrollbar {  /* Chrome, Safari 対応 */
    display:none;
  }
}

/* テキスト揃え可変 */
.contents-recruit-text {
  h3 {
    font-weight: bold;
  }
  @media screen and (max-width: 768px) {
    text-align: justify  }
  @media screen and (min-width: 769px) {
    text-align: center;
  }
}