$blue: #2778be;
$indigo: #5c4c9d;
$purple: #8266aa;
$pink: #ed65a3;
$red: #e35d6a;
$orange: #fd7e14;
$yellow: #ffcd39;
$green: #53b96a;
$teal: #66cc99;
$cyan: #45b5c6;

// デフォルトのdarkを1段階濃く
$dark: #212529;

// デフォルトのパンくずリストの区切り記号
$breadcrumb-divider: quote('>');

//角丸変数
$border-radius: .25rem !default;
$border-radius-lg: .25rem !default;
$border-radius-sm: .25rem !default;

// フォントサイズ
$spacer: 0.875rem !default;
$font-size-base:              0.875rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
// $line-height-base:            1.5 !default;
$line-height-base:            1.75 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      $spacer / 1.75 !default;
// 画像の弟要素の見出しの上マージン
$headings-margin-top:      $spacer / 1.25 !default;