@charset "UTF-8";

/* ズーム */
.iv-zoom{
    transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    transition: .8s;
}
.mv-zoom{
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transition: .8s;
}

/* フェード */
.iv-fade{
    opacity: 0;
    transition: .8s;
}
.mv-fade{
    opacity: 1.0;
    transition: .8s;
}

/* 回転 */
.iv-rotation{
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition: .8s;
}
.mv-rotation{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transition: .8s;
}

/* 3D回転 */
.iv-3d{
    transform:rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    transition: .8s;
}
.mv-3d{
    transform:rotateY(360deg);
    -webkit-transform:rotateY(360deg);
    transition: .8s;
}

/* 上移動 */
.iv-up{
    transform: translate(0,40px);
    -webkit-transform: translate(0,40px);
    transition: .8s;
}
.mv-up{
    transform: translate(0,0);
    -webkit-transform: translate(0,0);
    transition: .8s;
}

/* 3D回転＋ズームイン表示 */
.iv-3d-zoom{
    transform: scale(0, 0) rotateY(180deg);
    -webkit-transform: scale(0, 0) rotateY(180deg);
    transition: .8s;
}
.mv-3d-zoom{
    transform: scale(1, 1) rotateY(360deg);
    -webkit-transform: scale(1, 1) rotateY(360deg);
    transition: .8s;
}

/* 移動＋フェードイン表示 */
.iv-up-fade{
    opacity: 0;
    transform: translate(0,60px);
    -webkit-transform: translate(0,60px);
    transition: .8s;
}
.mv-up-fade{
    opacity: 1.0;
    transform: translate(0,0);
    -webkit-transform: translate(0,0);
    transition: .8s;
}
